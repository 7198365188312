#contact{
    overflow: hidden;
    /* height: 100vh; */
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    padding-top: 5rem;
}

.contactTItle{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
    color: #FFC374;
    /* border-bottom: 5px solid #FFC374; */
    
    
}
.havesome{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    margin-top: 3rem;

}

.cdetail{
    display: flex;
    justify-content: center;
    font-size: small;
    margin-top: 2rem;
}

.contactContainer{
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    /* justify-content: center;
    align-items: center; */
}

.form{
    height: 60vh;
    flex: 2;
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.712);
    box-shadow: 5px 5px 14px -1px rgba(0,0,0,0.53);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.ctus{
    font-size: 2rem;
    color: black;
    font-weight: 500;
}

#outlined-basic{
    padding: 0.7rem;
    border-radius: 0.7rem;
     border: 1px solid #7F9F80;
}

.contactSend{
    padding: 1rem;
    border: none;
    border-radius: 0.7rem;
    background-color: rgba(240, 248, 255, 0.692);
    box-shadow: 3px 3px 8px -1px rgba(0,0,0,0.53);
    cursor: pointer;
}
.socialsection{
    height: 60vh;
    flex: 1;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    
    /* background-color: blue; */
}

.socialTitle{
    font-size: 2rem;
}
.contactIcons{
    margin-right: 2rem;
    
}
.socialSingleIcons{
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
}
.allrightsdiv{
    display: flex;
    justify-content: center;
    margin: 1.5rem;
    
}
.allrights{

}



@media all and (max-width:768px) {
    #contact{
        height: fit-content;

        width: 90vw;
        /* max-width: 90rem; */
        margin: 0 auto;
        padding-top: 5rem;
        
    }
    .havesome{
        font-size: 2rem;
    }

    .cdetail{
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.822);
    }
    .contactContainer{
        display: grid;
        
        /* justify-content: space-around;
        align-items: flex-start; */
    }

    .socialsection{
        /* height: 50vh; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: auto;
        padding: 2rem;
        font-size: 0.8rem;
        
    }
    .form{
        height: 50vh;
        margin-bottom: 2rem;
       
    }
    .tt{
        width: 100%;
        /* background-color: #7F9F80; */
        margin-bottom: -5rem;
    }
    

    .socialIcons{
        margin-top: 2rem;
    }
}