.itemContainer{
    height:25rem;
    width: 20rem;
    /* width: 80vw; */
    
    /* border: 1px solid rgb(248, 248, 248); */
    border-radius: 2rem;
    margin: 1rem;
    display: flex;

    justify-content: center;
    align-items: center;
    

    flex-direction: column;
    /* justify-content: space-around; */

    /* background-color: rgb(47, 47, 59); */
    background-color: #20202075;
}

.projectLogo{
    /* height:15rem; */
    width: 18rem;
    object-fit: cover;
    /* background-color: aqua; */
}
.pTitle{
    font-size: 1.2rem;
    font-weight: 600;
}

.buttonLay{
    width: 20rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.demoBtn{
    height: 3rem;
    border: none;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    font-weight: 300;
    cursor: pointer;
}
.sourceBtn{
    height: 3rem;
    width: 7.5rem;
    border: none;
    border-radius: 1rem;
    background-color: #ffffff;
    cursor: pointer;
}