#projects{
    overflow: hidden;
    /* height: 100vh; */
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    /* padding-top: 2rem; */
}

.projectTItle{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
    color: #FFC374;
    /* border-bottom: 5px solid #FFC374; */
    
    
}

.projectGallery{
    display: flex;
    /* justify-content: space-between; */
   
    margin-top: 3rem;
    flex-wrap: wrap;
}


@media all and (max-width:768px) {

    #projects{
        /* width: 90vw; */
        /* max-width: 90rem; */
        margin: 0 auto;
   
    }

    .projectGallery{
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        flex-wrap: nowrap;
        flex: none;
        overflow-x: auto;
    }
    
}