#skills{
    overflow: hidden;
    /* height: 100vh; */
    width: 100vw;
    max-width: 90rem;
    margin: 5rem auto;
    
}

.skillTItle{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
    color: #FFC374;
    /* border-bottom: 5px solid #FFC374; */
    
    
}

.skillGallery{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* width: auto; */
}


@media all and (max-width:768px) {
    #skills{
        width:100vw;
        max-width: 90vw;
        /* margin: 5rem auto; */
        
    }
}