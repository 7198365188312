.serviceItem{
    background-color: rgba(255, 255, 255, 0.712);
    /* background-color: #ffe8c9; */
    /* background-color: #c8e0c9; */
    
    height:30rem;
    width: 20rem;
    border-radius: 2rem;
    margin: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: black;
    box-shadow: 3px 3px 7px 0px rgb(0, 0, 0);
}
.packTitle{
    font-size: 1.5rem;
    /* font-weight: 600; */
    margin-bottom: 1rem;
    margin-top: 1rem;
    /* color: ; */
}
.packFeatures{
    margin-bottom: 1rem;
    width: 80%;
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background-color: antiquewhite; */
    /* border-radius: 1rem;
    border-width: 5px;
    border-color: black; */
}

.buyButton{
    padding: 1rem;
    border-radius: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    border-width: 0px;
    width: 15rem;
    background-color: #124176;
    color: white;

}
.packPrice{
    font-size: 2rem;
    font-weight: 600;
    /* color: #FFC374; */
}


@media all and (max-width:768px) {

    .serviceItem{
        height:25rem;
        width: 20rem;
        border-radius: 2rem;
        margin: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    
    }
    .emp{
        width: 20rem;
        /* height: 1rem; */
    }
    
}