.navbar{
    height: 4.5rem;
    width: 90vw;
    max-width: 95rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    /* background-color: rgb(24, 24, 24); */
}
.navBarLogo{
    
    height: 4.5rem;
    width: 3.5rem;
}

.menu{
    display: none;
    
}
.desktopMenuListItem{
    margin: 0 1rem;
    color: aliceblue;
    cursor: pointer;
    font-size: 1.5rem;

}

.desktopMenuListItem:hover{
    color: #FFC374;
    padding-bottom: 0.5rem;
    border-bottom: 5px solid #FFC374;
}

.desktopMenuBtn{
    height: 3rem;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    border-style: none;
    border-radius: 1.5rem;
    background-color: #FFC374;
    cursor: pointer;
    color: #ffffff;
    
}




/* ANimations */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadeOut {
    100% { opacity: 0; }
    0% { opacity: 1; }
  }


@keyframes sideIn{
    
}

@media all and (max-width:768px) {

    .navbar{
        /* width: calc(100vw / 768)+'px'; */
        /* zoom: calc(100% / 768); */
        max-width: 83.5vw;
        /* overflow-x: hidden; */ 
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: rgba(24, 24, 24, 0);
        margin: 0;
    }

    .navBarLogo{
        position: absolute;
        left: 1.5rem;
        width: 13vw;
        /* object-fit: cover; */
    }
    .desktopMenu{
        position: absolute;
        display: none;
        
        top: 3rem;
        right:0px;
        margin-top: 3rem;
        background-color: rgb(0, 0, 0);
        width: 100%;
        animation: fadeIn 0.5s;
        padding-bottom: 1rem;
        
    }

    .menu{
        display: block;
        /* object-fit: cover; */
        width: 1.75rem;
        height: 1.75rem;
        position: absolute;
        margin-top:1.1rem;
        right: 0.9rem;
        cursor: pointer;
        
    }
    .desktopMenuListItem{
        width: 80vw;
        margin-top: 1rem;
        background-color: rgba(6, 8, 31, 0.856);
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
    }

    .desktopMenuListItem:hover{
        background-color: aqua;
    }

    .desktopMenuBtn{
        display: none;
    }
}
  
  