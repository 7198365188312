#intro{
    /* height: calc(100vh - 4rem); */
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    box-shadow: black;
    /* background-color: antiquewhite; */
    /* z-index: -999; */
}

/* fade/blur */

.bg{
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
    /* border-bottom: 1rem; */
    /* border-bottom: 5px solid #7F9F80; */
    box-shadow: 0 4px 2px -2px gray;
}

.introContent{
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 4rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.hello{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    /* color: yellow; */
    color: #7F9F80;
}

.introPara{
    font-size: medium;
    font-weight: 100;
}
.btn{
    height: 3.5rem;
    width: 9rem;
    background: white;
    margin: 5rem 0;
    border-radius: 2rem;
    padding: 0.75rem 1rem;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: inset;
    cursor: pointer;
}

.hire{
    cursor: pointer;
}



@media all and (max-width:768px) {  
    .bg{
        
        top: -1rem;
        right: -10rem;
        
    }

    
    
    .introContent{
        height: 100vh;
        width: 100vw;
        padding: 2rem;
        font-size: 2.5rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
       
    }
    
    .hello{
        font-size: 1.75rem;
        font-weight: 100;
    }
    
    .introName{
        font-size: 3rem;
        color: yellow;
    }
    
    .introPara{
        font-size: medium;
        font-weight: 200;
    }
    .btn{
        height: 4rem;
        width: 8rem;
        background: white;
        margin: 1rem 0;
        border-radius: 2rem;
        padding: 0.75rem 1rem;
        border: none;
        font-size: 1rem;
        font-weight: bold;
        box-shadow: inset;
        cursor: pointer;
    }
}