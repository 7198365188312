#about{
    overflow: hidden;
    /* height: 100vh; */
    width: 100vw;
    max-width: 90rem;
    margin: 5rem auto;
}

.aboutTItle{
    display: flex;
    justify-content: center;
    font-size: 3rem;
     /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
    font-weight: bold;
    padding-bottom: 0.5rem;
    color: #FFC374;
    /* border-bottom: 5px solid #FFC374; */
    
    
}

.aboutContainer{
    display: flex;
    width: 100%;
    height: 75%;
    /* flex-direction: column; */
    margin-top: 2rem;
    justify-content: space-around;
    align-items: center;
}
.mypic{
    width: 40rem;
    border: solid;
    /* border-width: 1rem; */
    border-radius: 1rem;
    border-color: #ffc37417;
    margin-right: 2rem;
}
.abtext{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: center;
    font-size: 1.2rem;
    
}

.type{
    font-size: 3rem;
    margin-bottom: 5rem;
    font-weight: 500;
    color: #7F9F80;
}

.aboutP{
    margin-bottom: 5rem;
    color: rgba(255, 255, 255, 0.788);

}

.cv{
    border-radius: 1.5rem;
    padding: 0.7rem;
    border: none;
    height: 3.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    font-size: 1rem;
    color: white;
    background-color: #7F9F80;
}

.abLink{
    cursor: pointer;
}

@media all and (max-width:768px) {
    #about{
        
        width: 90vw;
        max-width: 90rem;
        /* margin: 5rem auto; */
    }

    .aboutTItle{
        display: flex;
        justify-content: center;
        font-size: 3rem;
        
        font-weight: bold;
        padding-bottom: 0.5rem;
        color: #FFC374;
        /* border-bottom: 5px solid #FFC374; */
        
        
    }
    
    .aboutContainer{
        display: flex;
        /* width: 100%; */
        /* height: 75%; */
        flex-direction: column;
        margin-top: 2rem;
        align-items: center;
        justify-content: center;
        
    }
    .mypic{
        display: none;
        width: 40%;
        border: solid;
        /* border-width: 1rem; */
        border-radius: 1rem;
        border-color: #ffc37417;
        margin-bottom: 2rem;
        justify-self: center;
    }
    .abtext{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        justify-self: center;
        font-size: 1.2rem;

        
    }
    
    .type{
        font-size: 2rem;
        margin-bottom: 5rem;
        font-weight: 600;
        color: #7F9F80;
        text-align: center;
        
    }
    
    .aboutP{
        margin-bottom: 5rem;
        color: rgba(255, 255, 255, 0.788);
        text-align: center;
    }
    
    .cv{
        border-radius: 1.5rem;
        padding: 0.7rem;
        border: none;
        height: 4rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-weight: 700;
        font-size: 1rem;
        color: white;
        background-color: #7F9F80;
    }
}