.confirmContainer{
    height: 100svh;
    background-color: white;
    display: flex;
    flex-direction: row;
    color: black;
         font-family: -apple-system, BlinkMacSystemFont, sans-serif;

}
.leftSide{
width: 65svw;
padding-left: 2rem;
padding-right: 10rem;
display: flex;
/* justify-content: center; */
flex-direction: column;
}
.confirmBarLogo{
    height: 4.5rem;
    width: 3.5rem;
}
.leftTitle{
    font-size: 32px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
}
.rightSide{
    width: 35svw;
    display: flex;
    background-color: #113d6f2c;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.doubleInput{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputItem{
    display: flex;
    flex-direction: column;
    width: 20svw;
    margin-left: 4rem;
    margin-right: 5rem;
}

.inputLongItem{
    display: flex;
    flex-direction: column;
    width: 54svw;
    margin-left: 4rem;
    margin-right: 5rem;
}

.inputItem.multiline{
    display: flex;
    flex-direction: column;
    width: 50svw;
    margin-left: 4rem;
    margin-right: 5rem;
}
.inputBanner{
    font-size: 15px;
    margin-bottom: 5px;
}
.inputText{
    padding: 10px;
    margin-bottom: 7px;
}

.confirmButton{
    padding: 1rem;
    border-radius: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    width: 20svw;
    border-width: 0px;
    background-color: #113D6F;
    color: white;
}

.rightTitle{
    font-size: 32px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
}

.confirmButtonPannel{
    display: flex;
    flex-direction: column;
    width: max-content;
    align-items: center;

}

@media all and (max-width:768px) {

    .confirmContainer{
        height: max-content;
        width: 100vw;
        /* max-width: 90rem; */
        /* background-color: white; */
        display: flex;
        flex-direction: column;
        color: black;
             font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    
    }
    .leftSide{
        width: 100vw;
        padding-left: 0rem;
        padding-right: 0rem;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        flex-direction: column;
        margin-bottom: 20px;
        }

        .confirmBarLogo{
            height: 4.5rem;
            width: 3.5rem;
        }
        .leftTitle{
            font-size: 32px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            justify-content: space-around;
            color: black;
        }
        .rightSide{
            width: 100vw;
            display: flex;
            /* background-color: #113d6f2c; */
            flex-direction: column;
            align-items: center;
            /* justify-content: space-around; */
        }


        .doubleInput{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .inputItem{
            display: flex;
            flex-direction: column;
            width: 80svw;
            margin-left: 0rem;
            margin-right: 0rem;
        }
        .inputLongItem{
            display: flex;
            flex-direction: column;
            width: 80svw;
            margin-left: 0rem;
            margin-right: 0rem;
        }
        .inputItem.multiline{
            display: flex;
            flex-direction: column;
            width: 80vw;
            
        }


        .inputBanner{
            font-size: 15px;
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.514);
        }
        .inputText{
            padding: 10px;
            margin-bottom: 7px;
        }
        
        .confirmButton{
            padding: 1rem;
            border-radius: 1rem;
            font-weight: 600;
            margin-bottom: 1rem;
            width: 80svw;
            border-width: 0px;
            background-color: #113D6F;
            color: white;
        }
        
        .rightTitle{
            font-size: 32px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            justify-content: space-around;
        }

        .confirmButtonPannel{
            display: flex;
            flex-direction: column;
            width: max-content;
            align-items: center;
        }
}